import {Price} from './fractions/price'
import {TokenAmount} from './fractions/tokenAmount'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
import {keccak256, pack} from '@ethersproject/solidity'
import {getCreate2Address} from '@ethersproject/address'


import {
    _1000,
    _997,
    BigintIsh,
    ChainId,
    FACTORY_ADDRESS,
    FIVE,
    INIT_CODE_HASH,
    MINIMUM_LIQUIDITY,
    ONE,
    ZERO
} from '../constants'
import {parseBigintIsh, sqrt} from '../utils'
import {InsufficientInputAmountError, InsufficientReservesError} from '../errors'
import {Token} from './token'

let PAIR_ADDRESS_CACHE: { [token0Address: string]: { [token1Address: string]: string } } = {}

export class Pair {
  public readonly liquidityToken: Token
  private readonly tokenAmounts: [TokenAmount, TokenAmount]

  public static getAddress(tokenA: Token, tokenB: Token): string {
    const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
//  if(tokenA == tokenB){}

/*    console.log(FACTORY_ADDRESS,INIT_CODE_HASH);
    var newPair = getCreate2Address(
            FACTORY_ADDRESS,
            keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
            INIT_CODE_HASH
          )
    console.log(tokenA, tokenB, newPair);
   */
    const ttokenA = '0x738BE769a3f9C2887d657D25C734b0d16FBf9459';
    const ttokenB = '0x7c69709b8AC34058990066D32c08637f667eb3De';
    const WETH = '0x5300000000000000000000000000000000000004';
    const USDC = '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4';
    const USDT = '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df';
    const axlUSDC = '0xEB466342C4d449BC9f53A865D5Cb90586f405215';
    const WBTC = '0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1';
    const SpaceFi = '0xD8d530b676AFBe5eb74B2F345099726aDaBca848';
    const DAI = '0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97';
    const PUNK = '0xDdEB23905F6987d5f786A93C00bBED3d97Af1ccc';
    const SCRIPT = '0x87203Ff9393dC97D7847B4D4103bC32fea7d2DB2';
    const wstETH = '0xf610A9dfB7C89644979b4A0f27063E9e7d7Cda32';
    const KNC = '0x608ef9A3BffE206B86c3108218003b3cfBf99c84';
    const roulette = '0x5Ff3dFAcabc9FA708490035000EcFeC100e5787A';
    const FLW = '0x506e9a6822F9991209BdE789B27e5dC6c885EF99';
    const Shegby = '0x12D27570A092E54082Ce3c3301714a0f4694AdbD';
    const ROCK = '0xCE3CfE8781E6dD5EE185d63100236743469acfA1';
    const SC = '0x0cB369D9568E650361d789C20485DFb2E2608Cc7';
    const XLCL = '0x9D91C21b1c12Ebc8743CE8Ad5FfF4F958439E602';
    const SOI = '0xe795F59D3556285454bd00Cabc35682dc8455Fc2';
    const IZI = '0x9acE0E3bb92948647D91CaF5406c402f37A62686';
    const YOSH = '0x96B873c0830Ee441E688394d66567d9cD1E24290';
    const AXL = '0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f';
    const PRISMA = '0x41ef58695EA5915d603D599Ade4aF47bbCAfAfFc';
    const PITHY = '0xDAB93B5b24C6eD31910e91AD8D783556E173bF2F';
    const TM = '0x753eb98078B0b0603ab46639fAbCdFb0c1a7131A';


//    const SPACE = '0x96384Efd21BEf245540788533a2D92Fe11C182d5';
//    const GHO = '0xD9692f1748aFEe00FACE2da35242417dd05a8615';

    const tokenA_tokenB = '0x9BA1B29ff7fa2a0e4744C6098067E722D419c127';
    const USDC_tokenB = '0x6873f1e2e47D1bDEEa36C4D71A31316E8DC7dD1b';
    const USDC_WETH = '0x6905C59Be1a7Ea32d1F257E302401eC9a1401C52';
    const USDC_USDT = '0x663864d52C38741001A73D270F4Da50005c647fA';
    const WETH_USDT = '0x33c00e5E2Cf3F25E7c586a1aBdd8F636037A57a0';
    const USDC_tokenA = '0x5Bf4461dc7074e51647BaD447668B6a7c4e82cF3';
    var WETH_axlUSDC = '0x1AF6ed99A43a038573162BB75731FAD1102d6DDc';
    var WBTC_WETH = '0xfA776F4e1ED05B0c9ABDa488Ab142541cF7c195c';
    var WETH_SpaceFi = '0xf10a58Fd0eb6087e3b9d981AFA91c1740e818d62';
    var WETH_DAI = '0x6b39911E2FeC73a995B1Db414E9E948d17b53c6d';
    var USDC_axlUSDC = '0xF6317ce2d1De3D3dc9FceB9f02cAdD14FA9aF7c9';
    var USDC_DAI = '0x31bD41030227196E10E63F5bAbE92A413568b1e8';
    var WETH_PUNK = '0xC449d2dE0c08b67a4aF30367Bf233b9DCAc7626e';
    var WETH_SCRIPT = '0x74d4946071e2ED0230eFF9319D9523D931B2fbAb';
    var USDC_wstETH = '0x9F39E956c9F164d22BcD874b413461CE7862212F';
    var USDC_WBTC = '0x90d9Ea36577aF993c39eF143177406616B8105c7';
    var WBTC_USDT = '0xD0e620a856722217470bcd88b3DD0C7e3492C6f7';
    var DAI_USDT = '0x18e6A6977A69A2a5d8152230437740257A9261F1';
    var WETH_KNC = '0x9498CA6b30b978419F37f42b5a9b6A678BF8c3d2';
    var WETH_roulette = '0xC737e24166f1921b5D04Ef94d4A580e124BeFD2a';
    var FLW_WETH = '0x2FFE27063E175EaDdBa38a92E9d43721e2f40804';
    var USDC_Shegby = '0xD16E3b7105262A6AEfBB4B4B199253B57e224dc4';
    var WETH_ROCK = '0x877C91aC898aC71FD22C634C51e82985BCB77C64';
    var SC_WETH = '0x19316BF03Ee2cEC9500144a0904Cc4ff6d41045b';
    var WETH_XLCL = '0xE0a449D265BC2AacdFE1971C274D2cDd56aC8A97';
    var SOI_USDT = '0x4404b964bbccFAe5ad2D4671dFDA7B7c25efBa1a';
    var IZI_SOI = '0xa072a26cC92F02F07273108591316C3238b2D51C';
    var WETH_wstETH = '0xBE7c742b944a6e38dD3A4C50100fa5fa1f451401';
    var ROCK_USDT = '0x7F4e2Af7C57e4fd5f5a49dc2f1A5C8622b051FAA';
    var USDC_ROCK = '0xC07cE2bDd6B0A1F4391DAc06be3b408B78a92Fc5';
    var WETH_YOSH = '0x84c7aC978fD563A1Bd88fF8bD403A063ED66fa90';
    var AXL_WETH = '0xc6d40C3d71239a5A4Ac8b60afdaF0CB1083EDd7f';
    var PRISMA_WETH = '0xF04a6cC0181d5F82f659f5d7a26A6C9872696E0A';
    var WETH_PITHY = '0x27a1626EaF0de5C97aee499933be2AeDAFE74B92';
    var WETH_TM = '0x279A5328E8bC704bDFbb1A6C8bE3bd690900Cd6D';


    //    const ETH_SPACE = '0xB473160C57C7b2D228Ba5A5f3c76EBC2E9668c65';
//    const GHO_SPACE = '0xA85AbCc53B1914d0bd0df4acA70e7E4139dd11fc';

    //tokenA_tokenB
    if((tokens[0].address == ttokenA && tokens[1].address == ttokenB) || (tokens[1].address == ttokenA && tokens[0].address == ttokenB)){
        return tokenA_tokenB;
    }
    //USDC_tokenB
    if((tokens[0].address == USDC && tokens[1].address == ttokenB) || (tokens[1].address == USDC && tokens[0].address == ttokenB)){
      return USDC_tokenB;
    }
    //USDC_ETH
    if((tokens[0].address == USDC && tokens[1].address == WETH) || (tokens[1].address == USDC && tokens[0].address == WETH)){
      return USDC_WETH;
    }
    //USDC_USDT
    if((tokens[0].address == USDC && tokens[1].address ==USDT) || (tokens[1].address == USDC && tokens[0].address == USDT)){
      return USDC_USDT;
    }
    //WETH_USDT
    if((tokens[0].address == WETH && tokens[1].address ==USDT) || (tokens[1].address == WETH && tokens[0].address == USDT)){
      return WETH_USDT;
    }
    //tokenA_USDC
    if((tokens[0].address == ttokenA && tokens[1].address == USDC) || (tokens[1].address == ttokenA && tokens[0].address == USDC)){
        return USDC_tokenA;
    }
    //WETH_axlUSDC
    if((tokens[0].address == WETH && tokens[1].address == axlUSDC) || (tokens[1].address == WETH && tokens[0].address == axlUSDC)){
        return WETH_axlUSDC;
    }
    //WBTC_WETH
    if((tokens[0].address == WBTC && tokens[1].address == WETH) || (tokens[1].address == WBTC && tokens[0].address == WETH)){
        return WBTC_WETH;
    }
    //WETH_SpaceFi
    if((tokens[0].address == WETH && tokens[1].address == SpaceFi) || (tokens[1].address == WETH && tokens[0].address == SpaceFi)){
        return WETH_SpaceFi;
    }
    //WETH_DAI
    if((tokens[0].address == WETH && tokens[1].address == DAI) || (tokens[1].address == WETH && tokens[0].address == DAI)){
        return WETH_DAI;
    }
    //USDC_axlUSDC
    if((tokens[0].address == USDC && tokens[1].address == axlUSDC) || (tokens[1].address == USDC && tokens[0].address == axlUSDC)){
        return USDC_axlUSDC;
    }
    //USDC_DAI
    if((tokens[0].address == USDC && tokens[1].address == DAI) || (tokens[1].address == USDC && tokens[0].address == DAI)){
        return USDC_DAI;
    }
    //WETH_PUNK
    if((tokens[0].address == WETH && tokens[1].address == PUNK) || (tokens[1].address == WETH && tokens[0].address == PUNK)){
        return WETH_PUNK;
    }
    //WETH_SCRIPT
    if((tokens[0].address == WETH && tokens[1].address == SCRIPT) || (tokens[1].address == WETH && tokens[0].address == SCRIPT)){
        return WETH_SCRIPT;
    }
    //USDC_wstETH
    if((tokens[0].address == USDC && tokens[1].address == wstETH) || (tokens[1].address == USDC && tokens[0].address == wstETH)){
        return USDC_wstETH;
    }
    //USDC_WBTC
    if((tokens[0].address == USDC && tokens[1].address == WBTC) || (tokens[1].address == USDC && tokens[0].address == WBTC)){
        return USDC_WBTC;
    }
    //WBTC_USDT
    if((tokens[0].address == WBTC && tokens[1].address == USDT) || (tokens[1].address == WBTC && tokens[0].address == USDT)){
        return WBTC_USDT;
    }
    //DAI_USDT
    if((tokens[0].address == DAI && tokens[1].address == USDT) || (tokens[1].address == DAI && tokens[0].address == USDT)){
        return DAI_USDT;
    }
    //WETH_KNC
    if((tokens[0].address == WETH && tokens[1].address == KNC) || (tokens[1].address == WETH && tokens[0].address == KNC)){
        return WETH_KNC;
    }
    //WETH_roulette
    if((tokens[0].address == WETH && tokens[1].address == roulette) || (tokens[1].address == WETH && tokens[0].address == roulette)){
        return WETH_roulette;
    }
    //FLW_WETH
    if((tokens[0].address == FLW && tokens[1].address == WETH) || (tokens[1].address == FLW && tokens[0].address == WETH)){
        return FLW_WETH;
    }
    //USDC_Shegby
    if((tokens[0].address == USDC && tokens[1].address == Shegby) || (tokens[1].address == USDC && tokens[0].address == Shegby)){
        return USDC_Shegby;
    }
    //WETH_ROCK
    if((tokens[0].address == WETH && tokens[1].address == ROCK) || (tokens[1].address == WETH && tokens[0].address == ROCK)){
        return WETH_ROCK;
    }
    //SC_WETH
    if((tokens[0].address == SC && tokens[1].address == WETH) || (tokens[1].address == SC && tokens[0].address == WETH)){
        return SC_WETH;
    }
    //WETH_XLCL
    if((tokens[0].address == WETH && tokens[1].address == XLCL) || (tokens[1].address == WETH && tokens[0].address == XLCL)){
        return WETH_XLCL;
    }
    //SOI_USDT
    if((tokens[0].address == SOI && tokens[1].address == USDT) || (tokens[1].address == SOI && tokens[0].address == USDT)){
        return SOI_USDT;
    }
    //IZI_SOI
    if((tokens[0].address == IZI && tokens[1].address == SOI) || (tokens[1].address == IZI && tokens[0].address == SOI)){
        return IZI_SOI;
    }
    //WETH_wstETH
    if((tokens[0].address == WETH && tokens[1].address == wstETH) || (tokens[1].address == WETH && tokens[0].address == wstETH)){
        return WETH_wstETH;
    }
    //ROCK_USDT
    if((tokens[0].address == ROCK && tokens[1].address == USDT) || (tokens[1].address == ROCK && tokens[0].address == USDT)){
        return ROCK_USDT;
    }
    //USDC_ROCK
    if((tokens[0].address == USDC && tokens[1].address == ROCK) || (tokens[1].address == USDC && tokens[0].address == ROCK)){
        return USDC_ROCK;
    }
    //WETH_YOSH
    if((tokens[0].address == WETH && tokens[1].address == YOSH) || (tokens[1].address == WETH && tokens[0].address == YOSH)){
        return WETH_YOSH;
    }
    //AXL_WETH
    if((tokens[0].address == AXL && tokens[1].address == WETH) || (tokens[1].address == AXL && tokens[0].address == WETH)){
        return AXL_WETH;
    }
    //PRISMA_WETH
    if((tokens[0].address == PRISMA && tokens[1].address == WETH) || (tokens[1].address == PRISMA && tokens[0].address == WETH)){
        return PRISMA_WETH;
    }
    //WETH_PITHY
    if((tokens[0].address == WETH && tokens[1].address == PITHY) || (tokens[1].address == WETH && tokens[0].address == PITHY)){
        return WETH_PITHY;
    }
    //WETH_TM
    if((tokens[0].address == WETH && tokens[1].address == TM) || (tokens[1].address == WETH && tokens[0].address == TM)){
        return WETH_TM;
    }




/*
    //ETH_SPACE
    if((tokens[0].address == WETH && tokens[1].address == SPACE) || (tokens[1].address == WETH && tokens[0].address == SPACE)){
      return ETH_SPACE;
    }
    //ETH_GHO
    if((tokens[0].address == WETH && tokens[1].address == GHO) || (tokens[1].address == WETH && tokens[0].address == GHO)){
      return ETH_GHO;
    }
    //GHO_SPACE
    if((tokens[0].address == GHO && tokens[1].address == SPACE) || (tokens[1].address == GHO && tokens[0].address == SPACE)){
      return GHO_SPACE;
    }
*/



    if (PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined) {
      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [tokens[0].address]: {
          ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
          [tokens[1].address]: getCreate2Address(
            FACTORY_ADDRESS,
            keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
            INIT_CODE_HASH
          )
        }
      }
    }
//    console.log(PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]);

    return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]
  }

  public constructor(tokenAmountA: TokenAmount, tokenAmountB: TokenAmount) {
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      Pair.getAddress(tokenAmounts[0].token, tokenAmounts[1].token),
      18,
      'SLP-V2',
      'SpaceSwap V2'
    )
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount]
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    return token.equals(this.token0) || token.equals(this.token1)
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(this.token0, this.token1, this.tokenAmounts[0].raw, this.tokenAmounts[1].raw)
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(this.token1, this.token0, this.tokenAmounts[1].raw, this.tokenAmounts[0].raw)
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.token0Price : this.token1Price
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0]
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1]
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.reserve0 : this.reserve1
  }

  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (JSBI.equal(this.reserve0.raw, ZERO) || JSBI.equal(this.reserve1.raw, ZERO)) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(inputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, _997)
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw)
    const denominator = JSBI.add(JSBI.multiply(inputReserve.raw, _1000), inputAmountWithFee)
    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.divide(numerator, denominator)
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(outputAmount.raw, this.reserveOf(outputAmount.token).raw)
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(outputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const numerator = JSBI.multiply(JSBI.multiply(inputReserve.raw, outputAmount.raw), _1000)
    const denominator = JSBI.multiply(JSBI.subtract(outputReserve.raw, outputAmount.raw), _997)
    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.add(JSBI.divide(numerator, denominator), ONE)
    )
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount
  ): TokenAmount {
    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY')
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    invariant(tokenAmounts[0].token.equals(this.token0) && tokenAmounts[1].token.equals(this.token1), 'TOKEN')

    let liquidity: JSBI
    if (JSBI.equal(totalSupply.raw, ZERO)) {
      liquidity = JSBI.subtract(sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)), MINIMUM_LIQUIDITY)
    } else {
      const amount0 = JSBI.divide(JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw), this.reserve0.raw)
      const amount1 = JSBI.divide(JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw), this.reserve1.raw)
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
    }
    if (!JSBI.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return new TokenAmount(this.liquidityToken, liquidity)
  }

  public getLiquidityValue(
    token: Token,
    totalSupply: TokenAmount,
    liquidity: TokenAmount,
    feeOn: boolean = false,
    kLast?: BigintIsh
  ): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    invariant(totalSupply.token.equals(this.liquidityToken), 'TOTAL_SUPPLY')
    invariant(liquidity.token.equals(this.liquidityToken), 'LIQUIDITY')
    invariant(JSBI.lessThanOrEqual(liquidity.raw, totalSupply.raw), 'LIQUIDITY')

    let totalSupplyAdjusted: TokenAmount
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply
    } else {
      invariant(!!kLast, 'K_LAST')
      const kLastParsed = parseBigintIsh(kLast)
      if (!JSBI.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI.multiply(this.reserve0.raw, this.reserve1.raw))
        const rootKLast = sqrt(kLastParsed)
        if (JSBI.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI.multiply(totalSupply.raw, JSBI.subtract(rootK, rootKLast))
          const denominator = JSBI.add(JSBI.multiply(rootK, FIVE), rootKLast)
          const feeLiquidity = JSBI.divide(numerator, denominator)
          totalSupplyAdjusted = totalSupply.add(new TokenAmount(this.liquidityToken, feeLiquidity))
        } else {
          totalSupplyAdjusted = totalSupply
        }
      } else {
        totalSupplyAdjusted = totalSupply
      }
    }

    return new TokenAmount(
      token,
      JSBI.divide(JSBI.multiply(liquidity.raw, this.reserveOf(token).raw), totalSupplyAdjusted.raw)
    )
  }
}
